var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("details", { staticClass: "govuk-details" }, [
    _c("summary", { staticClass: "govuk-details__summary" }, [
      _c(
        "span",
        {
          staticClass: "govuk-details__summary-text",
          domProps: { textContent: _vm._s(_vm.summary) }
        },
        [_vm._t("summary")],
        2
      )
    ]),
    _c("div", { staticClass: "govuk-details__text" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }